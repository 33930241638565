@keyframes showAndHide {
  0% {
    transform: translateY(-10px);
    box-shadow: none;
    opacity: 0;
  }

  20% {
    transform: translateY(0);
    box-shadow: 0px 17px 17px rgba(0, 0, 0, 0.25);
    opacity: 1;
  }

  80% {
    transform: translateY(0);
    box-shadow: 0px 17px 17px rgba(0, 0, 0, 0.25);
    opacity: 1;
  }

  100% {
    transform: translateY(-10px);
    box-shadow: none;
    opacity: 0;
  }
}

.notification-box {
  position: fixed;
  right: 2.5rem;
  top: 100px;
  z-index: 20;
  padding: 8px 10px;
  font-size: bold;
  box-shadow: 0px 17px 17px rgba(0, 0, 0, 0.25);
  animation: showAndHide 5s ease-in-out;
}

.notification-box p {
  margin: 0;
}

.error {
  border: 2px solid #CC3333;
  background-color: #EFD3D3;
  color:#CC3333
}

.success {
  border: 2px solid #46785F;
  background-color: #BFE7D3;
  color:  #46785F;
}